<template>
  <main>
    <page-header-compact> Lista de Citologias </page-header-compact>
    <div class="container-fluid">
      <div class="card mt-2">
        <div class="card-body">
          <div class="row">
            <div class="col-lg-8">
              <div class="form-group">
                <label class="form-label font-weight-bolder">Sede</label>
                <auto-complete-sede v-model="sede"></auto-complete-sede>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12">
              <div class="form-group">
                <label class="form-label font-weight-bolder">Fecha</label>
                <input type="date" class="form-control" v-model="pagination.params.fecha" />
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12">
              <div class="form-group">
                <label class="form-label font-weight-bolder">Profesional</label>
                <v-select-user v-model="pagination.params.id_user"></v-select-user>
              </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12">
              <div class="form-group">
                <label class="form-label font-weight-bolder">Usuario</label>
                <input class="form-control form-control-sm" v-model="pagination.params.usuario" />
              </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-12">
              <div class="pt-4">
                <button class="btn btn-info mt-2 btn-sm" @click="getCitologias()">
                  <i class="fa fa-filter fa-fw"></i> Filtrar
                </button>
                <button class="btn btn-warning mt-2 btn-sm" @click="resetFilters()">
                  <i class="fa fa-broom fa-fw"></i> Limpiar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card mt-2">
        <div class="card-body">
          <data-grid :data="pagination" @changePage="changePage($event)" @perPageChange="perPageChange($event)"
            @search="search($event)" :search-box="false">
            <template #grid>
              <grid-table class="table table-bordered table-sm">
                <template #head>
                  <grid-cell-header class="bg-light" v-for="(h, i) in headers" :key="`h_${i}${h}`">{{ h
                    }}</grid-cell-header>
                </template>
                <template #body>
                  <grid-row v-for="(cit, i) in pagination.data" :key="`${i}_citologias`">
                    <grid-cell class="text-center font-weight-bolder">
                      {{ cit.id_resultado }}
                    </grid-cell>
                    <grid-cell class="text-center font-weight-bolder">
                      {{ cit.id }}
                    </grid-cell>
                    <grid-cell class="text-center">
                      <strong>{{ cit.documento }}</strong>
                      <div>{{ cit.usuario }}</div>
                    </grid-cell>
                    <grid-cell class="text-center font-weight-bolder">{{ cit.sede }}</grid-cell>
                    <grid-cell class="text-center font-weight-bolder text-uppercase">
                      {{ cit.fecha | formatDate }}
                    </grid-cell>
                    <grid-cell class="text-center font-weight-bolder text-uppercase">{{ cit.user }}</grid-cell>
                    <grid-cell class="justify-content-between">
                      <span :class="mapAppointmentState(cit.resultado).badgeClass" :key="cit.id">
                        <i :class="mapAppointmentState(cit.resultado).badgeIcon"></i>
                        {{ mapAppointmentState(cit.resultado).badgeText }}
                      </span>
                    </grid-cell>
                    <grid-cell>
                      <div class="d-flex justify-content-center">
                     
                      <div v-if="cit.resultado === 'NO' && _can('resultados.create')">
                        <router-link target="_blank" :to="{
                            name: 'historias.citologia.resultado.create',
                            params: {
                              id: cit.id,
                            },
                          }" class="btn btn-primary btn-icon shadow-sm btn-xs" title="Llenar Resultados">
                          <i class="fas fa-plus fa-fw"></i>
                        </router-link>
                      </div>
                      <button v-if="cit.resultado === 'SI'" title="Imprimir Resultado"
                        class="btn btn-transparent text-success btn-icon  btn-sm" @click="printResults(cit.id)">
                        <i class="fa fa-print fa-fw"></i>
                      </button>
                      <router-link :to="{ name: 'historias.citologia.resultado.edit', params: { id: cit.id } }"
                        v-if="cit.resultado === 'SI'" title="Editar Resultado"
                        class="btn btn-transparent text-info btn-icon  btn-sm">
                        <i class="fa fa-edit fa-fw"></i>
                      </router-link>
                      <button v-if="cit.resultado === 'SI' && cit.id_user_resultado===user.id" title="Eliminar Resultado"
                        class="btn btn-transparent text-danger btn-icon  btn-sm"
                        @click="deleteResultados(cit.id_resultado)">
                        <i class="fa fa-trash fa-fw"></i>
                      </button>
                    </div>
                    </grid-cell>

                  </grid-row>
                </template>
              </grid-table>
            </template>
          </data-grid>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import PageHeaderCompact from "../../../components/layouts/content/page-header-compact";
import DataGrid from "../../../components/common/utilities/DataGrid/DataGrid";
import GridTable from "../../../components/common/utilities/DataGrid/GridTable";
import GridCellHeader from "../../../components/common/utilities/DataGrid/GridCellHeader";
import GridRow from "../../../components/common/utilities/DataGrid/GridRow";
import GridCell from "../../../components/common/utilities/DataGrid/GridCell";
import citologiaService from "../../../services/citologiaService";
import VSelectUser from "../../../components/common/vSelectUser";
import typeAppService from "../../../services/typeAppService";
import Toast from "@/components/common/utilities/toast";
import AutoCompleteSede from "../../../components/common/autocompletes/AutoCompleteSede";
import Swal from "sweetalert2";
export default {
  name: "citologia.vue",
  components: {
    VSelectUser,
    GridCell,
    GridRow,
    GridCellHeader,
    GridTable,
    DataGrid,
    PageHeaderCompact,
    AutoCompleteSede
  },
  async created() {
    this.getCitologias();
    const { data } = await typeAppService.index();
    this.hcAppLink = data.find((app) => app.name === "HISTORIA").domain;
  },
  data() {
    return {
      headers: ["No. Res.","No. mtra.", "Usuario", "Sede", "Fecha", "Profesional", "Resultados","Acciones"],
      pagination: {
        data: [],
        current_page: 1,
        page: 1,
        per_page: 5,
        search: "",
        total: "",
        params: {
          id_user: "",
          fecha: "",
          usuario: "",
          estado: "",
          id_sede: "",
        },
      },
      hcAppLink: "",
      sede: {}
    };
  },
  methods: {
    printResults(id) {
      window.open(citologiaService.print(id));
    },
    resetFilters() {
      this.pagination.params.id_user = "";
      this.pagination.params.usuario = "";
      this.pagination.params.fecha = "";
      this.pagination.params.estado = "";
      this.pagination.params.id_sede = "";
      this.getCitologias();
    },
    mapAppointmentState(resultado) {
      if (resultado === "NO") {
        return {
          rowClass: "bg-danger-soft",
          badgeClass: "badge badge-marketing badge-danger-soft",
          badgeIcon: "fa fa-times-circle fa-fw",
          badgeText: "Sin registrar",
        };
      } else if (resultado === "SI") {
        return {
          rowClass: "bg-success-soft",
          badgeClass: "badge badge-marketing badge-success-soft",
          badgeIcon: "fa fa-check fa-fw",
          badgeText: "Registrados",
        };
      }
      [resultado];
    },
    async getCitologias() {
      try {
        this.LoaderSpinnerShow();
        const response = (
          await citologiaService.index(this.pagination.search, this.pagination)
        )?.data;
        this.pagination.data = response.data.map((citologia) => {
          return {
            id: citologia.id,
            id_usuario: citologia.historia.usuario.id,
            documento: citologia.historia.usuario.documento,
            usuario: `${citologia.historia.usuario.nombre_1} ${citologia.historia.usuario.nombre_2} ${citologia.historia.usuario.apellido_1} ${citologia.historia.usuario.apellido_2}`,
            sede: `${citologia.historia.sede.NOMBRE_SEDE}`,
            user: citologia.historia.user.name,
            id_user: citologia.historia.id_user,
            fecha: citologia.fecha_lectura,
            resultado: citologia.resultado ? "SI" : "NO",
            id_resultado: citologia.resultado?.id || '',
            id_user_resultado: citologia.resultado?.id_user||''
          };
        });
        this.pagination.current_page = response.current_page;
        this.pagination.total = response.total;
        this.LoaderSpinnerHide();
      } catch (e) {
        console.error(e);
        this.LoaderSpinnerHide();
        Toast.fire({
          icon: "error",
          title: "Ocurrio un error al procesar la solicitud",
        });
      }
    },
    changePage(page) {
      this.pagination.page = page;
      this.getCitologias();
    },
    perPageChange(per_page) {
      this.pagination.per_page = per_page;
      this.pagination.page = 1;
      this.getCitologias();
    },
    search(query) {
      this.pagination.search = query;
      this.pagination.page = 1;
      this.getCitologias();
    },
    async deleteResultados(id_resultado) {

      const result = await Swal.fire({
        title: "¿Quiere eliminar el resultado #"+id_resultado+"?",
        text: "Esta opción no es reversible",
        showCancelButton: true,
        showConfirmButton: true,
        confirmButtonText: 'Sí, Eliminar',
        cancelButtonText: 'No',
        icon: "question",
        showLoaderOnConfirm: false,
        preConfirm: async () => {
          try {
            this.LoaderSpinnerShow();
            await citologiaService.delete(id_resultado);
            this.LoaderSpinnerHide();
          } catch (e) {
            console.error(e);
            this.LoaderSpinnerHide();
            Swal.showValidationMessage('ha ocurrido un error al procesar la solicitud');
          }
        },
      });

      if (result.isConfirmed) {
        Toast.fire({
          icon: 'success',
          title: 'Datos eliminados con exito'
        });

        this.getCitologias();
      }
    },

  },
  computed: {
    user() {
      return this.$store.state.user;
    },
  },
  watch: {
    sede: function (newVal, oldVal) {
      if (newVal.ID !== oldVal.ID) {
        this.pagination.params.id_sede = newVal.ID;
      }
    }
  },
};
</script>
